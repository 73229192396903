<template>
  <div>
    <h1>Calendar</h1>

    <div v-if="isCalendarLoading" class="text-center pt-3">
      <b-spinner label="Loading..." />
    </div>

    <full-calendar v-else ref="calendar" :options="calendarOptions" />
    <div class="pb-3"></div>

    <b-modal
      id="details-modal"
      ok-only
      title="Booking Details"
      cancel-variant="outline-secondary"
    >
      <div
        v-if="isModalLoading"
        class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <template
        v-for="(value, key, index) in modal"
        v-else
      >
        <b-form-group
          v-if="value"
          :id="`input-group-${key}`"
          :key="index"
          :label="key"
          :label-for="`input-${key}`"
          class="capitalize"
        >
          <b-form-input
            :id="`input-${key}`"
            :value="value"
            readonly
          />
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BSpinner,
  BModal,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import { toastFailure } from '@/libs/toastification'
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
    BSpinner,
    FullCalendar,
    BModal,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      isCalendarLoading: true,
      events: [],
      isModalLoading: true,
      modal: {
        boat: null,
        package: null,
        date: null,
        time: null,
        name: null,
        email: null,
        phone: null,
        details: null,
      },
    }
  },
  computed: {
    calendarOptions() {
      return {
        plugins: [ timeGridPlugin, dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        eventClick: this.handleEventClick,
        events: this.events,
      }
    },
  },
  async mounted() {
    axios
      .get(
        'api/bookings-calendar'
      )
      .then(response => {
        this.isCalendarLoading = false
        this.events = response.data.data
      })
      .then(() => {
        this.$refs.calendar.getApi().refetchEvents()
      })
      .catch(() => {
        toastFailure('Something went wrong.')
      })
  },
  methods: {
    handleEventClick(info) {
      info.jsEvent.preventDefault()

      this.$root.$emit('bv::show::modal', 'details-modal')
      this.openDetailsModal(info.event.id)
    },
    openDetailsModal(bookingId) {
      this.isModalLoading = true
      axios.get(`api/booking-details/${bookingId}`)
        .then(response => {
          this.modal = { ...response.data.data }
          this.isModalLoading = false
        })
        .catch(() => {
          toastFailure('Something went wrong.')
        })
    },
  },
}
</script>

<style>
  @media (max-width: 400px) {
    .fc .fc-toolbar-title {
      font-size: 1.25em;
    }
  }

  @media (max-width: 340px) {
    .fc .fc-toolbar-title {
      font-size: 1em;
    }
  }
</style>
